import React from 'react'
import SiteLayout from '../components/SiteLayout'
import { Row, Collapse } from 'antd'

const { Panel } = Collapse;

const FAQ = ({ location }) => (
  <SiteLayout location={location}>
    <Row type="flex" justify="center">
      <div className="row-faq">
        <h1>Frequently Asked Questions</h1>
        <p>The purpose of this <strong>FAQ</strong> is to answer the most common questions our potential partners or external developers may have. Even though many questions will need to be solved via email/phone, we can still answer some things.</p>
        <h2>Getting Started</h2>
        <Collapse accordion>
          <Panel header="Who can use the Strands Finance API (SFA)?" key="1">
            <p>SFA can be used by banks, partners and integrators to create better experiences with the minimum effort.
              Strands strives to enhance collaborative and innovative thinking across financial institutions.
              SFA allows Strands' clients and technology partners to build services on top of its APIs and rebuild their online platform,
              bringing new and improved banking experiences to life.</p>
          </Panel>
          <Panel header="What does SFA include?" key="2">
            <p>SFA is a set of different components:</p>
            <ul>
              <li><strong>Strands FM API:</strong> Full access to the most complete fintech API in the market. Categorization, Expenses statistics, Budgeting, Goals, Networth, Invoicing, etc.</li>
              <li><strong>Strands UI Kit:</strong> From buttons to full widgets and account aggregation screens, the Strands UI Kit is the only UI Kit that provides all the necessary components to build an online banking platform for the new generations.</li>
              <li><strong>Strands FM SDKs:</strong> Do you use Java? C#? PHP? We cover all languages with our SDKds….</li>
              <li><strong>Sandbox:</strong> Build and test your application in our secure environments. Get new test data daily, ……</li>
            </ul>
          </Panel>
          <Panel header="Why to partner with Strands?" key="3">
            <p>Stay up to date in state of the art banking innovation, more than 700 banks use Strands already.</p>
          </Panel>
          <Panel header="Where can I benefit from partnering with Strands?" key="4">
            <p>Leverage online/mobile banking projects delivering digital money management and event-drive-insights solutions.</p>
          </Panel>
          <Panel header="How can I be trained?" key="5">
            <p>Contact Strands education team to get trained on SFA.</p>
          </Panel>
        </Collapse>
      </div>
    </Row>
  </SiteLayout>
)

export default FAQ
